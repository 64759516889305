export default [{
    url: 'https://kaola-haitao.oss.kaolacdn.com/88ae45d8-cbc4-422d-b44a-6cdc33719a10_68x54.png',
    selectedUrl: 'https://kaola-haitao.oss.kaolacdn.com/88ae45d8-cbc4-422d-b44a-6cdc33719a10_68x54.png'
}, {
    url: 'https://kaola-haitao.oss.kaolacdn.com/d74372b1-4c35-4aa6-a2e6-61c8b14f16fc_66x53.png',
    selectedUrl: 'https://kaola-haitao.oss.kaolacdn.com/f1ff627a-f856-4dca-acf5-23f58fb3c8e1_66x53.png'
}, {
    url: 'https://kaola-haitao.oss.kaolacdn.com/0e714a48-c4df-41a0-99dc-61e36aaa5de5_66x53.png',
    selectedUrl: 'https://kaola-haitao.oss.kaolacdn.com/a48da056-81af-4a6d-8ea0-3f5ef4ca009c_66x53.png'
}];
