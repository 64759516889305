<template>
    <!--
        LEVEL_ONE(1, "低级赚客"),
        LEVEL_TWO(2, "中级赚客"),
        LEVEL_THREE(3, "高级赚客"),
    -->
    <div class="zhuanke-level">
        <div class="level" :style="levelStyle">
            <p class="desc">当前账号等级</p>
            <p class="value">
                <img :src="levelIcon[zhuankeLevel - 1].selectedUrl" alt="">
                <span>{{ zhuankeLevel | formatZhuankeLevel }}</span>
            </p>
        </div>
        <div class="level-progress" :style="progressStyle">
            <div class="level-x"
                 v-for="(item, index) in levelIcon" :key="index"
            >
                <div class="level-x-inner">
                    <img class="level-x-img" :src="item.url" alt=""
                         v-if="zhuankeLevel - 1 < index"
                    >
                    <img class="level-x-img-selected" :src="item.selectedUrl" alt=""
                         v-if="zhuankeLevel - 1 >= index"
                    >
                    <p class="level-x-text"
                       :style="{
                           fontWeight: zhuankeLevel - 1 === index ? 'bold' : 'normal',
                           color: zhuankeLevel - 1 === index ? '#151515' : '#666'
                       }"
                    >
                        {{ (index + 1) | formatZhuankeLevel }}
                    </p>
                    <div class="level-x-desc" v-if="showLevelDesc">
                        <div @click="jump2LevelInfoPage">
                            <p v-if="index === 1 && zhuankeLevel === 1">
                                <span>做任务升级至中级，</span>
                                <span class="link">赚取更高佣金 &gt;</span>
                            </p>
                            <p v-if="index === 2 && zhuankeLevel === 2">
                                <span>做任务升级至高级，</span>
                                <span class="link">赚取更高佣金 &gt;</span>
                            </p>
                            <p v-if="index === 2 && zhuankeLevel === 3">
                                <span class="link">当前已享最⾼佣⾦权益，继续保持哦 > &gt;</span>
                            </p>
                        </div>
                    </div>
                </div>
                <p class="line"
                   :class="{
                       active: zhuankeLevel - 2 >= index
                   }"
                   v-if="index !== levelIcon.length - 1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import levelIcon from './level-icon';

export default {
    props: {
        zhuankeLevel: {
            type: Number
        },
        levelStyle: {
            type: Object,
            default: () => ({})
        },
        progressStyle: {
            type: Object,
            default: () => ({})
        },
        showLevelDesc: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            levelIcon
        };
    },

    filters: {
        formatZhuankeLevel(l) {
            switch (l) {
            case 1:
                return '初级';
            case 2:
                return '中级';
            case 3:
                return '高级';
            default:
                return '';
            }
        }
    },

    methods: {
        jump2LevelInfoPage() {
            this.$router.push('/level');
        }
    }
};
</script>

<style lang="scss" scoped>
.zhuanke-level {
    .level {
        display: flex;
        padding: 14px 28px;
        background: #fff;

        .desc {
            color: #333;
            font-size: 14px;
            font-weight: bold;
        }
        .value {
            display: flex;
            margin-left: 16px;

            img {
                margin-right: 2px;
                width: 23px;
                height: 18px;
            }

            span {
                color: #5f5f5f;
                font-size: 13px;
                font-weight: bold;
            }
        }
    }

    .level-progress {
        padding: 14px 28px 68px;
        display: flex;
        background: #fff;

        .level-x {
            display: flex;
            align-items: center;

            &-inner {
                position: relative;
                text-align: center;
                img {
                    width: 45px;
                    height: 36px;
                }
                .level-x-text {
                    margin-top: 6px;
                    font-size: 18px;
                    color: #666;
                }
                .level-x-desc {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    white-space: nowrap;
                    font-size: 12px;
                    color: #252525;
                    cursor: pointer;
                    p {
                        margin-top: 10px;
                        position: relative;
                        width: 300px;
                        height: 30px;
                        line-height: 30px;
                        background: #F3F3F3;
                        border-radius: 20px;

                        .link {
                            color: #ff0000;
                        }

                        &::after {
                            position: absolute;
                            top: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            content: ' ';
                            width: 0;
                            height: 0;
                            border: 10px solid transparent;
                            border-bottom-color: #F3F3F3;
                        }
                    }
                }
            }

            .line {
                margin-left: -10px;
                margin-right: -10px;
                margin-bottom: 22px;
                width: 200px;
                height: 3px;
                background: #e7e7e7;

                &.active {
                    background: #FFDEB0;
                }
            }
        }
    }
}
</style>
