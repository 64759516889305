<template>
    <div class="home" style="background:#ffff">
        <homeHead />
        <div>
            <div class="title">
                <span>企业资质审核</span>
            </div>
            <div class="home-main">
                <progressTab 
                    class="progressTab"
                    :active-progress="activeNumber" 
                    v-if="!showAllForm"
                />
                <div class="qualAudit" v-if="showAllForm">
                    <div>
                        <img :src="aduitIngImg" v-if="ReviewTipsStatus" alt="审核中icon" class="statusIcon">
                        <img :src="aduitFailImg" v-else alt="审核失败icon" class="statusIcon">
                    </div>
                    <div>
                        <p style="font-size:20px;margin:20px auto" 
                           v-if="ReviewTipsStatus"
                        >
                            {{ ReviewTips }}
                        </p>
                        <p style="color:red;font-size:20px;margin:20px auto" 
                           v-else
                        >
                            {{ ReviewTips }}
                        </p>
                    </div>
                    <div>
                        <p v-if="ReviewTipsStatus">
                            我们将在1~3个工作日内完成审核，审核结果将以短信、或邮件形式通知，请您注意查收。
                        </p>
                        <div v-else>
                            <div data-v-3fc699a7="" 
                                 role="alert" 
                                 class="el-alert el-alert--error is-light"
                                 style="width:30%;margin:0 auto"
                            >
                                <div class="el-alert__content">
                                    <span class="el-alert__title" v-html="failReasonShow(AuditFailTip)">
                                        {{ failReasonShow(AuditFailTip) }}
                                    </span>
                                </div>
                            </div>
                            <div class="btn-area">
                                <el-button type="primary"
                                           round
                                           @click="editQualAuditInfo()"
                                >
                                    修改资料
                                </el-button>
                            </div>
                            <p>
                                审核结果已发送至您的手机和邮箱，请注意查收。
                            </p>
                        </div>
                    </div>
                </div>
                <div 
                    class="normal_form"
                    :class="{form_area:showAllForm}"
                >  
                    <!-- 企业联系人信息 -->
                    <el-form 
                        :model="contactInfo" 
                        status-icon 
                        :rules="contactRules" 
                        ref="contactInfo" 
                        class="demo-ruleForm"
                        label-width="150px" 
                        v-show="activeNumber == 0"
                    >
                        <el-form-item label="联系人姓名" 
                                      prop="contactName"
                        >
                            <el-input 
                                v-model="contactInfo.contactName" 
                                autocomplete="off" 
                                placeholder="请输入企业联系人姓名"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="联系人邮箱" 
                            prop="contactEmailAddress"
                        >
                            <el-input 
                                v-model="contactInfo.contactEmailAddress" 
                                autocomplete="off" 
                                placeholder="用于接收审核结果"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="联系人手机" 
                            prop="contactPhoneNum"
                        >
                            <el-input v-model="contactInfo.contactPhoneNum" 
                                      placeholder="用于接收审核结果"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="联系人地址" 
                            prop="contactAddress"
                        >
                            <el-input v-model="contactInfo.contactAddress" 
                                      type="textarea"
                                      :rows="2"
                                      placeholder="请输入企业联系人地址"
                            />
                        </el-form-item>
                    </el-form>
                    <!-- 企业信息 -->
                    <el-form
                        :model="companyInfo" 
                        status-icon 
                        :rules="companyRules" 
                        ref="companyInfo" 
                        class="demo-ruleForm"
                        label-width="150px" 
                        v-show="activeNumber == 1"
                    >
                        <el-form-item 
                            label="公司名称" 
                            prop="companyTitle"
                        >
                            <el-input 
                                v-model="companyInfo.companyTitle" 
                                autocomplete="off" 
                                placeholder="请按照营业执照填写"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="公司法人姓名" 
                            prop="companyRepresentPersonName"
                        >
                            <el-input 
                                v-model="companyInfo.companyRepresentPersonName" 
                                autocomplete="off" 
                                placeholder="请按照营业执照填写"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="法人身份证照片" 
                            prop="companyRepresentPersonIDCardPersonPic"
                        >
                            <div class="imgupload">
                                <imgUpload 
                                    v-model="companyInfo.companyRepresentPersonIDCardPersonPic"
                                    :tip="'正面'"
                                    :disabled="showAllForm"
                                />
                                <img class="example-img-front" 
                                     src="https://kaola-haitao.oss.kaolacdn.com/15ce65c6-77d3-428a-936b-f68be7e32124_108_71.png" 
                                     alt="身份证正面示例图"
                                     v-if="activeNumber < 3"
                                >
                            </div>
                            <div class="imgupload">
                                <imgUpload 
                                    v-model="
                                        companyInfo.companyRepresentPersonIDCardNationalEmblemPic
                                    "
                                    :tip="'反面'"
                                    :disabled="showAllForm"
                                />
                                <img class="example-img-behind" 
                                     src="https://kaola-haitao.oss.kaolacdn.com/8a0fac32-8c15-4841-824e-8a9f80d9387a_88_71.png" 
                                     alt="身份证反面示例图"
                                     v-if="activeNumber < 3"
                                >
                            </div> 
                            <span class="check-img-tip" v-if="!showAllForm">只支持jpg/png文件，且不超过10M</span>
                        </el-form-item>
                        <el-form-item 
                            label="统一社会信用代码" 
                            prop="companyUnifiedSocialCreditCode"
                        >
                            <el-input 
                                v-model="companyInfo.companyUnifiedSocialCreditCode" 
                                placeholder="请按照营业执照填写"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="营业执照" 
                            prop="businessLicensePic"
                        >
                            <imgUpload v-model="companyInfo.businessLicensePic"
                                       class="imgupload"
                                       :disabled="showAllForm"
                            />
                            <span v-if="!showAllForm" class="check-img-tip">只支持jpg/png文件，且不超过10M</span>
                        </el-form-item>
                        <el-form-item 
                            label="开户许可证" 
                            prop="accountOpenLicensePic"
                        >
                            <imgUpload 
                                class="imgupload"
                                v-model="companyInfo.accountOpenLicensePic"
                                :disabled="showAllForm"
                            />
                            <span v-if="!showAllForm" class="check-img-tip">只支持jpg/png文件，且不超过10M</span>
                        </el-form-item>
                    </el-form>
                    <!-- 收款信息 -->
                    <el-form 
                        :model="creditedAccount" 
                        status-icon 
                        :rules="AccountRules" 
                        label-width="150px" 
                        ref="creditedAccount"
                        class="demo-ruleForm"
                        v-show="activeNumber == 2"
                    >
                        <el-form-item 
                            label="银行账户名" 
                            prop="receiveMoneyBankAccount"
                            :error="bankNameErrorMessage"
                        >
                            <el-input 
                                v-model="creditedAccount.receiveMoneyBankAccount" 
                                autocomplete="off" 
                                placeholder="请输入银行账户名，需与公司名称一致"
                                @change="checkbankName"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="收款银行账号" 
                            prop="receiveMoneyBankCardNum"
                        >
                            <el-input 
                                v-model="creditedAccount.receiveMoneyBankCardNum" 
                                autocomplete="off" 
                                placeholder="请输入收款银行账号"
                            />
                        </el-form-item>
                        <el-form-item 
                            label="银行名称" 
                            prop="receiveMoneyBankName"
                        >
                            <el-select v-model="creditedAccount.receiveMoneyBankName" 
                                       placeholder="请选择开户银行"
                            >
                                <el-option
                                    v-for="item in bankList"
                                    :key="item.bankId"
                                    :label="item.bankName"
                                    :value="item.bankName"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item 
                            label="支行名称" 
                            prop="receiveMoneySubBankName"
                        >
                            <el-input 
                                v-model="creditedAccount.receiveMoneySubBankName" 
                                autocomplete="off" 
                                placeholder="请填写对应银行支行名称"
                            />
                        </el-form-item>
                    </el-form>
                    <div>
                        <infoForm
                            :form-data="form"
                            ref="form" 
                            class="demo-ruleForm"
                            v-if="showAllForm"
                            :isdivider="true"
                        />
                    </div>
                    <div class="btn-area" v-if="!showAllForm">
                        <div class="two-btn">
                            <el-button 
                                class="special-nofocus-btn"
                                type="primary" 
                                round
                                @click="last()" 
                                v-if="activeNumber>0 && !showAllForm"
                            >
                                上一步
                            </el-button>
                            <el-button class="special-nofocus-btn"
                                       type="primary" 
                                       v-if="activeNumber < 2 && !showAllForm"
                                       @click="next(activeNumber)" 
                                       :disabled="isbtnable"
                                       round
                            >
                                下一步
                            </el-button>
                            <el-button class="special-nofocus-btn"
                                       type="primary" 
                                       v-if="activeNumber == 2"
                                       @click="next(activeNumber)" 
                                       :disabled="isbtnable"
                                       round
                            >
                                提交审核
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <homeFoot />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import progressTab from '../../components/companyQualAduit/progressTab';
import imgUpload from '../../components/companyQualAduit/imgUpload';
import homeHead from '../../components/homeLayout/homeHead';
import homeFoot from '../../components/homeLayout/homeFoot';
import infoForm from '../../components/companyQualAduit/infoForm';

export default {
    data(){
        let validatePersonIDCard = (rule, value, callback) => {
            if(!value
            || !this.companyInfo.companyRepresentPersonIDCardNationalEmblemPic){
                callback(new Error('请上传身份证的正反面'));
                return;
            }
            callback();
        };
        let validateBankAccount = (rule, value, callback) => {
            if(value!=this.companyInfo.companyTitle){
                callback(new Error('银行账户名需与公司名称一致'));
                return;
            }
            callback();
        };

        let normalvalid = (message) => ({
            required: true,
            message,
            trigger: 'blur'
        });                         
        return{
            logo: 'https://kaola-haitao.oss.kaolacdn.com/c0cd6999-71c0-40ce-be1f-e227d95b9ada_420_80.png',
            tokaola: 'https://www.kaola.com/',
            privarcyUrl: 'https://you.kaola.com/common/page.html?key=Privacy_policy',
            trustWebicon: '//kaola-haitao.oss.kaolacdn.com/d720d83b55a04b6f932ea845c673c5bf.png',
            aduitIngImg: 'https://kaola-haitao.oss.kaolacdn.com/c81513ab-0957-474b-8a0b-6429210d9474_46_46.png',
            aduitFailImg: 'https://kaola-haitao.oss.kaolacdn.com/00fe72b1-e3fa-4918-b81a-2b60a8611332_46_46.png',
            ReviewTipsStatus: true,
            ReviewTips: '资料提交成功，审核中',
            AuditFailTip: '',
            showAllForm: false,
            activeNumber: 0,
            nextbtnIsabled: true,
            contactInfo: {
                contactName: '',
                contactEmailAddress: '',
                contactPhoneNum: '',
                contactAddress: ''
            },
            companyInfo: {
                companyTitle: '',
                companyRepresentPersonName: '',
                companyRepresentPersonIDCardPersonPic: '',
                companyRepresentPersonIDCardNationalEmblemPic: '',
                companyUnifiedSocialCreditCode: '',
                businessLicensePic: '',
                accountOpenLicensePic: ''
            },
            creditedAccount: {
                receiveMoneyBankAccount: '',
                receiveMoneyBankCardNum: '',
                receiveMoneyBankName: '',
                receiveMoneySubBankName: ''
            },
            form: {
            },
            bankList: [],
            showforms: true,
            bankNameErrorMessage: '',
            contactRules: {
                contactName: [
                    normalvalid('请输入企业联系人姓名')

                ],
                contactPhoneNum: [
                    { 
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {validator(rule, value, callback){
                        if(/^1[23456789]\d{9}$/.test(value) == false){
                            callback(new Error('请输入正确的手机号'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'}
                ],
                contactEmailAddress: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
                contactAddress: [
                    normalvalid('请输入企业联系人地址')
                ]
            },
            companyRules: {
                companyTitle: [
                    normalvalid('请输入公司名称')
                ],
                companyRepresentPersonName: [
                    normalvalid('请输入法人姓名')
                ],
                companyRepresentPersonIDCardPersonPic: [
                    {
                        required: true,
                        message: '请上传身份证的正反面',
                        trigger: 'blur'
                    },
                    { validator: validatePersonIDCard, trigger: 'blur' }
                ],
                companyUnifiedSocialCreditCode: [
                    {
                        required: true,
                        message: '统一社会信用代码',
                        trigger: 'blur'
                    },
                    {validator(rule, value, callback){
                        if(/^[A-Z0-9]{8,30}$/.test(value) == false){
                            callback(new Error('长度需为8-30位字符,只能由大写英文、数字组成'));
                        } else{
                            callback();
                            
                        }
                    }, trigger: 'blur'}
                ],
                businessLicensePic: [
                    {
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    },
                    {validator(rule, value, callback){
                        if(!value){
                            callback(new Error('请上传营业执照'));
                            return;
                        } 
                        callback();
                    }, trigger: 'blur'}
                ],
                accountOpenLicensePic: [
                    {
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    },
                    {validator(rule, value, callback){
                        if(!value){
                            callback(new Error('请上传开户许可证'));
                            return;
                        } 
                        callback();
                    }, trigger: 'blur'}
                ]
            },
            AccountRules: {
                receiveMoneyBankAccount: [
                    {
                        validator: validateBankAccount, trigger: blur, required: true
                    }
                ],
                receiveMoneyBankCardNum: [
                    normalvalid('请输入收款银行账号')
                ],
                receiveMoneyBankName: [
                    {
                        required: true,
                        message: '请选择开户银行',
                        trigger: 'blur'
                    }
                ],
                receiveMoneySubBankName: [
                    normalvalid('请填写对应银行支行名称')
                ]
            } 
        };
    },
    components: {
        progressTab, imgUpload, homeHead, homeFoot, infoForm
    },
    computed: {
        ...mapState([
            'isLogin',
            'kaolaEmailAddress',
            'kaolaNickName',
            'kaolaPhoneNum',
            'zhuankeType',
            'companyQualInfo'
        ]),
        receiveMoneyBankId(){
            let name = this.creditedAccount.receiveMoneyBankName;
            if(name){
                let receiveBank = this.bankList.find((v) => v.bankName == name);
                return receiveBank.bankId;
            }
            return '';
        },
        isbtnable(){
            if(this.activeNumber == 0){
                return this.validBtn('contactInfo');
            }
            if(this.activeNumber == 1){
                return this.validBtn('companyInfo');
            }
            if(this.activeNumber == 2){
                return this.validBtn('creditedAccount');
            }
            return false;
        }
    },
    async created(){
        this.getSupportBankInfo();
        this.showFormStatus(this.companyQualInfo);

    },
    methods: {
        async getSupportBankInfo(){
            const data = await this.$request({
                url: '/api/user/getSupportBankInfo'
            });
            if(data){
                this.bankList = data;
            }
        },
        /* eslint-disable */
        showFormStatus(body){
            if(!body){
                return;
            }
            this.showFormInfo(body);
            if(body.status === 2){
                this.activeNumber = 5;
                this.showAllForm = true;
                return;
            }
            if(body.status === 4){
                this.activeNumber = 5;
                this.showAllForm = true;
                this.ReviewTips ='企业资质审核失败，原因如下：';
                this.ReviewTipsStatus = false;
                this.AuditFailTip = body.auditFailureReason;
                return;
            }
            this.$router.push({
                path: '/promotion/goods'
            });
        },
        showFormInfo(body){
            this.forEachObj(body, this.contactInfo);
            this.forEachObj(body, this.companyInfo);
            this.forEachObj(body, this.creditedAccount);
            this.form = body;
        },
        forEachObj(obj, emptyobj){
            Object.keys(emptyobj).forEach((key) => {
                if(obj.hasOwnProperty(key)){
                    emptyobj[key] = obj[key];
                }
            });
        },
        last(){
            this.activeNumber--;
        },
        next(activeNumber){
            if(activeNumber == 0){
                this.validForm('contactInfo');
                return;
            }
            if(activeNumber == 1){
                this.validForm('companyInfo');
                return;
            }
            this.validForm('creditedAccount');

        },
        validForm(refName){
            this.$refs[refName].validate((valid) => {
                if (valid) {
                    this.activeNumber++;
                    if(refName === 'creditedAccount'){
                        this.submitForm();
                    }
                    return;
                } 
                return false;
            });
        },
        async submitForm(){
            this.form = { ...this.contactInfo, 
                ...this.companyInfo, 
                ...this.creditedAccount};
            this.form.receiveMoneyBankId = this.receiveMoneyBankId;
            const data = await this.$request({
                url: '/api/user/saveCompanyQualificationInfo',
                method: 'post',
                origin: true,
                data: {
                    companyQualificationInfo: this.form
                }
            });
            if(data.code === 200){
                this.showAllForm = true;
                this.ReviewTips ='资料提交成功，审核中';
                this.ReviewTipsStatus = true;
                return;
            }
            this.$message.error(data.desc);
            
        },
         validBtn(refName){
                let status = false;
                Object.keys(this[refName]).forEach((key) => {
                if(!this[refName][key]){
                    status = true;
                    return status;
                }
            });
            return status;
        },
        editQualAuditInfo(){
            this.showAllForm = false;
            this.activeNumber = 0;
        },
        checkbankName(value){
            this.bankNameErrorMessage = '';
            if(!value){
                this.bankNameErrorMessage = '请输入银行账户名，需与公司名称一致';
            }
            if(value == this.companyInfo.companyTitle){
                this.bankNameErrorMessage = '';
                return;
            }
            this.bankNameErrorMessage = '银行账户名需与公司名称一致';
        },
        failReasonShow(data){
            let reg = new RegExp("\n","g");
            let dataNew = ''
            if(data) {
                dataNew = data.replace(reg,"<br>");
            }
            return dataNew;
        }
    }
    
};
 

</script>
<style lang="scss">
.home .form_area .el-input.is-disabled .el-input__inner{
    width: 300px;
}
.home .normal_form .el-input.is-disabled .el-input__inner{
    max-width: 300px;
}
.home .normal_form .el-form-item .el-form-item__content{
    min-width: 400px;
}
.el-form-item__content .el-textarea.is-disabled .el-textarea__inner{
         max-width: 300px;
}

.special-nofocus-btn:focus{
    background-color: #FF1E32;
}

</style>
<style lang="scss" scoped>
    .home{
        margin: -16px;
        height: 100%;
        &-main{
            background: #ffffff;
            min-height: 600px;
            padding-bottom: 10px;
        }
        .progressTab{
            width: 55%;
            margin: 0 auto;
            padding-top: 30px;
        }
        .title{
            width: 100%;
            margin: 1% 0;
            height: 30px;
            position: relative;
        }
        .title span{
            font-size: 25px;
            position: absolute;
            left: 8.5%;
        }
        .normal_form{
            max-width: 620px;
            padding: 20px;
            margin: 0 auto;
            padding-top: 50px;
        }
        .form_area{
            margin: 0 auto;
            margin-top: 20px;
            box-sizing: border-box;
            min-width: 550px;
            padding: 20px;
            border: 0.5px solid #cecdcd;
            h4{
                margin: 10px 0;
            }
        }
        .statusIcon{
            display: block;
            margin: 0 auto;
        }
    }
    .banner{
        width:100%;
        height: 10%;
        background-color:red;
    }
    .show-form{
        width: 50%;
        margin: 0 auto;
    }
     .btn-area{
           display: flex;
           justify-content:center;
            background: #ffffff;
           padding: 20px 0;
            padding-bottom: 10px;
        }
    .two-btn{
        min-width: 95px;
        margin: 0 auto;
        .el-button{
            margin: 0 15px;
            width: 102px;
        }
    }
     .imgupload{
         position: relative;
        display: inline-block;
        margin-right:5%;
    }
      .img-identity-card{
          position: absolute;
          top: 38%;
          left: 39%;
    }
    .example-img-front{
        margin-left:10%;
    }
    .example-img-behind{
        margin-left:15%;
    }
    .check-img-tip{
        position: absolute;
        min-width: 220px;

    }
    .qualAudit p{
        text-align: center;
    }
    .el-form-item{
        width: 70%;
    }
    .tip {
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  white-space: normal;
  z-index: 20;
}
   
  
</style>
