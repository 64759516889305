<template>
    <div class="page">
        <div class="breadcrumb">
            <el-breadcrumb
                separator="/"
                style="font-size: 12px;"
            >
                <el-breadcrumb-item :to="{ path: '/promotion/goods' }">考拉赚客</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/accountManagement' }">账号管理</el-breadcrumb-item>
                <el-breadcrumb-item>等级权益</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <template v-if="!!info">
            <template v-if="info.zhuankeLevel">
                <div v-if="info">
                    <zhuanke-level-progress
                        :zhuanke-level="info.zhuankeLevel"
                        :show-level-desc="false"
                        :progress-style="{
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            background: '#f5f5f5'
                        }"
                    />

                    <div class="level-data">
                        <div class="arrow" :style="levelDataStyle" />
                        <p class="next-desc">{{ info.zhuankeLevel | formatNextLevelDesc }}</p>
                        <p class="tip">每⽇推⼴数据将于第⼆天24点前更新</p>
                        <div class="level-task">
                            <div class="task task1">
                                <p class="title">任务1</p>
                                <div class="content">
                                    <div class="inner">
                                        <div class="progress">
                                            <circle-progress
                                                :percentage="info | formatPercentage('userNum', 'nextUserNum')"
                                            />
                                            <div class="desc">
                                                <template v-if="info.zhuankeLevel === 3 || info.nextUserNum - info.userNum <= 0">
                                                    <p style="white-space: nowrap;">已完成！</p>
                                                </template>
                                                <template v-else>
                                                    <p>还需</p>
                                                    <p>
                                                        <span class="hight-light">{{ info.nextUserNum - info.userNum }}</span>人
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="words">
                                            <p class="tip">成交人数</p>
                                            <p class="number">
                                                <span class="num">{{ info.userNum }}</span>
                                                <span class="next-num">（目标≥{{ info.nextUserNum }}）</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="inner" style="margin-left: 30px;">
                                        <div class="progress">
                                            <circle-progress
                                                :percentage="info | formatPercentage('orderAmount', 'nextOrderAmount')"
                                            />
                                            <div class="desc">
                                                <template v-if="info.zhuankeLevel === 3 || handleOrderAmountLevel(info) <= 0">
                                                    <p style="white-space: nowrap;">已完成！</p>
                                                </template>
                                                <template v-else>
                                                    <p>还需</p>
                                                    <p>
                                                        <span class="hight-light">&yen;{{ handleOrderAmountLevel(info) }}</span>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="words">
                                            <p class="tip">有效订单金额</p>
                                            <p class="number">
                                                <span class="num">&yen;{{ info.orderAmount }}</span>
                                                <span class="next-num">（目标≥&yen;{{ info.nextOrderAmount }}元）</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="or">
                                <p>或</p>
                            </div>
                            <div class="task task2">
                                <p class="title">任务2</p>
                                <div class="content">
                                    <div class="inner">
                                        <div class="progress">
                                            <circle-progress
                                                :percentage="info | formatPercentage('vipNum', 'nextVipNum')"
                                            />
                                            <div class="desc">
                                                <template v-if="info.zhuankeLevel === 3 || info.nextVipNum - info.vipNum <= 0">
                                                    <p style="white-space: nowrap;">已完成！</p>
                                                </template>
                                                <template v-else>
                                                    <p>还需</p>
                                                    <p>
                                                        <span class="hight-light">{{ info.nextVipNum - info.vipNum }}</span>人
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="words">
                                            <p class="tip">黑卡推广张数</p>
                                            <p class="number">
                                                <span class="num">{{ info.vipNum }}</span>
                                                <span class="next-num">（目标≥{{ info.nextVipNum }}）</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="level-rule-container" v-if="!!info">
                    <zhuanke-level-rule :vipLockTimeList="info.vipLockTimeList || []"
                                        :zhuankeLevel="info.zhuankeLevel"
                    />
                </div>

                <div class="see-rule">
                    <el-link href="https://you.kaola.com/common/page.html?key=zhuanke_level" target="_blank">
                        等级规则说明 &gt;
                    </el-link>
                </div>
            </template>
            <template v-else>
                <p>未参与赚客分级</p>
            </template>
        </template>
    </div>
</template>

<script>
import ZhuankeLevelProgress from '../../components/zhuanke-level/zhuanke-level-progress';
import CircleProgress from '../../components/common/circle-progress';
import LevelRule from '../../components/zhuanke-level/level-rule';
import safeNumber from '../../utils/safe-number';

export default {

    components: {
        'zhuanke-level-progress': ZhuankeLevelProgress,
        'circle-progress': CircleProgress,
        'zhuanke-level-rule': LevelRule
    },

    data() {
        return {
            info: null
        };
    },

    created() {
        this.getLevelInfo();
    },

    computed: {
        levelDataStyle() {
            if(!this.info) {
                return {};
            }
            if(this.info.zhuankeLevel === 1) {
                return {
                    left: '264px'
                };
            }
            return {
                left: '490px'
            };
        }
    },

    filters: {
        formatNextLevelDesc(l) {
            if(l === 1) {
                return '近90⽇完成任务1或任务2，即可升级为【中级】';
            }
            if(l === 2) {
                return '近90⽇完成任务1或任务2，即可升级为【⾼级】';
            }
            if(l === 3) {
                return '近90⽇完成任务1或任务2，可保持【⾼级】';
            }
        },

        formatPercentage(info, key, nextKey) {
            if(info.zhuankeLevel === 3) {
                return 100;
            }
            return Number((info[key] / info[nextKey] * 100).toFixed(0));
        }
    },

    methods: {
        async getLevelInfo() {
            this.info = await this.$request({
                url: '/api/user/level'
            });
        },

        handleOrderAmountLevel(info) {
            return safeNumber.minus(info.nextOrderAmount, info.orderAmount);
        }
    }
};
</script>

<style lang="scss" scoped>
.page {
    .breadcrumb{
        background: #ffffff;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 10px;
    }

    .level-data {
        position: relative;
        background: #fff;
        padding: 9px 22px 18px;
        border-radius: 5px;

        .arrow {
            position: absolute;
            top: -20px;
            border: 10px solid transparent;
            border-bottom-color: #fff;
        }

        .next-desc {
            color: #000;
            font-size: 13px;
            font-weight: bold;
        }
        .tip {
            margin-top: 5px;
            font-size: 13px;
            color: #a4a4a4;
        }

        .level-task {
            display: flex;
            margin-top: 13px;
            border-radius: 12px;

            .or {
                position: relative;
                width: 70px;
                text-align: center;
                p {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 30px;
                    height: 30px;
                    background-color: #151515;
                    background-image: linear-gradient(140deg, #2A2A2A 11%, #101010 53%);
                    border-radius: 50%;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 30px;
                }
            }

            .task {
                background: #FEF8F1;
                padding: 8px 28px 12px 24px;

                .title {
                    text-align: center;
                    font-size: 16px;
                    color: #000000;
                    font-weight: bold;
                }

                .content {
                    margin-top: 20px;
                    display: flex;

                    .inner {
                        display: flex;
                        align-items: center;

                        .progress {
                            position: relative;

                            .desc {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate3d(-50%, -50%, 0);
                                font-size: 13px;
                                text-align: center;
                                .hight-light {
                                    color: #ff0000;
                                    font-weight: bold;
                                }
                            }
                        }

                        .words {
                            margin-left: 16px;
                            .tip {
                                font-size: 14px;
                                color: #181818;
                            }
                            .num {
                                color: #000;
                                font-weight: bold;
                            }
                            .next-num {
                                color: #666;
                            }
                        }
                    }
                }
            }
        }
    }

    .level-rule-container {
        margin-top: 10px;
        background-color: #fff;
        padding: 18px 20px 18px 46px;
    }

    .see-rule {
        margin-bottom: 200px;
        margin-top: 10px;
        background-color: #fff;
        padding: 18px 20px 18px 46px;
    }
}
</style>
