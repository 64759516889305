
<template>
    <el-progress type="circle"
                 :percentage="percentage"
                 :stroke-width="10"
                 color="#FE2A46"
                 :width="100"
                 :show-text="false"
    />
</template>

<script>
export default {
    props: {
        percentage: Number
    }
};
</script>
