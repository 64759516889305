<template>
    <div class="ne-image-row">
        <div class="ne-image-row__container">
            <elUpload
                :action="action"
                list-type="picture-card"
                :show-file-list="false"
                :before-upload="checkImg" 
                :http-request="handleUpload"
                accept="image/jpg,image/jpeg,image/png"
            >
                <div v-if="value" class="ne-image-row__image" :style="{backgroundImage: `url('${value}')`}" @click.stop>
                    <span class="ne-image-row__actions">
                        <span class="ne-image-row__action" @click="handlePreview(value)">
                            <i class="el-icon-zoom-in" />
                        </span>
                        <span v-if="!disabled" class="ne-image-row__action" @click="handleRemove">
                            <i class="el-icon-delete" />
                        </span>
                    </span>
                </div>
                <div v-if="!value">
                    <i class="el-icon-plus" />
                    <span class="tip">{{ tip }}</span>
                </div>
            </elUpload>
            <elDialog :visible.sync="dialogVisible"
                      class="showimgDialog"
            >
                <img class="show-uploadImg" :src="value">
            </elDialog>
        </div>
    </div>
</template>
<script>
export default {
    name: 'imgUpload',
    data() {
        return {
            imageUrl: this.value || '',
            dialogVisible: false
        };
    },
    props: {
        action: {
            type: String,
            default: '/api/upload'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: String
        },
        tip: {
            type: String
        },
        imgUrl: {
            type: String
        }
    },
    methods: {
        checkImg(file) {
            const isJPG = file.type === ('image/jpeg'|| 'image/jpg');
            const isPNG = file.type === 'image/png';
            const isJPGorPNG = isJPG || isPNG;
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isJPG && !isPNG) {
                this.$message.error('只支持jpg/png文件');
            }
            if (!isLt10M) {
                this.$message.error('上传头像图片大小不能超过 10MB!');
            }
            return isJPGorPNG && isLt10M;
        },
        async handleUpload(options) {
            const fd = new FormData();
            fd.append('file', options.file, options.file.name || 'image.jpg');
            const data = await this.$request({
                method: 'post',
                url: '/api/upload',
                params: fd
            });
            this.$emit('input', data.url);
        },
        handlePreview(url){
            this.dialogImageUrl = url;
            this.dialogVisible = true;
        },
        handleRemove() {
            this.$emit('input', null);
        }
    }
};
</script>
<style lang="scss">
.ne-image-row{
     .el-upload--picture-card{
           width: 130px;
            height: 130px;
     }
     .el-dialog{
         width: 640px;
        max-height: 690px;
     }
     .el-dialog .el-dialog__body{
         width: 640px;
         max-height: 660px;
     }
     .el-dialog__header{
         max-width: 600px;
         max-height: 600px;
     }
}

</style>
<style lang="scss" scoped>
 .ne-image-row {
        .el-upload--picture-card {
            width: 130px;
            height: 130px;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: #fff;
        }
        .el-icon-plus{
            width: 130px;
            height: 130px;
        }
        .tip{
            position: absolute;
            top: 30px;
            left: 52px;
            z-index: 100;
        }

        .el-dialog__header {
            border: none;
        }

    .ne-image-row__container {
        display: flex;
        align-items: center;
    }

    .ne-image-row__desc-container {
        margin-left: 10px;
    }

    .ne-image-row__desc {
        line-height: 21px;
        margin-bottom: 4px;
    }

    .ne-image-row__limit-desc {
        line-height: 21px;
    }
    
    .ne-image-row__example {
        line-height: 21px;
        color: #409EFF;
        cursor: pointer;
    }

    .ne-image-row__image {
        width: 130px;
        height: 130px;
        margin: -1px;
        display: block;
        cursor: default;
        background: #fff no-repeat center center;
        background-size: contain;

        &:hover .ne-image-row__actions {
            display: block;
        }
    }

    .ne-image-row__actions {
        display: none;
        width: 100%;
        height: 100%;
        line-height: 130px;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .ne-image-row__action {
        & + .ne-image-row__action {
            margin-left: 10px;
        }

        i {
            font-size: 20px;
            color: #fff;
            text-align: center;
            cursor: pointer;
        }
    }
    .disabled .el-upload--picture-card{
        display: none;
    }
    .show-uploadImg{
       max-width: 600px;
       max-height: 600px;
        display: block;
        margin: 0 auto;
    }
     }
 
</style>
