<template>
    <table class="companyTable"  
           cellpadding="10" 
           cellspacing="0"
    >
        <h4>企业联系人信息</h4>
        <tr>
            <td class="td_left">联系人姓名</td>
            <td class="td_right">{{ formData.contactName }}</td>
        </tr>
        <tr>
            <td class="td_left">联系人邮箱</td>
            <td class="td_right">{{ formData.contactEmailAddress }}</td>
        </tr>
        <tr>
            <td class="td_left">联系人手机</td>
            <td class="td_right">{{ formData.contactPhoneNum }}</td>
        </tr>
        <tr>
            <td class="td_left">联系人地址</td>
            <td class="td_right">{{ formData.contactAddress }}</td>
        </tr>
        <el-divider v-if="isdivider" />         
        <h4 class="tr_h5">企业信息</h4>
        <tr>
            <td class="td_left">公司名称</td>
            <td class="td_right">{{ formData.companyTitle }}</td>
        </tr>
        <tr>
            <td class="td_left">公司法人姓名</td>
            <td class="td_right">{{ formData.companyRepresentPersonName }}</td>
        </tr>
        <tr v-if="hidesensitiveInfo">
            <td class="td_left">法人身份证照片</td>
            <td class="td_right">
                <div class="img-show" style="margin-right:15px">
                    <imgUpload v-model="formData.companyRepresentPersonIDCardPersonPic"
                               :disabled="true"
                    />
                </div>
                <div class="img-show">
                    <imgUpload v-model="formData.companyRepresentPersonIDCardNationalEmblemPic"
                               :disabled="true"
                    />
                </div>
            </td>
        </tr>
        <tr>
            <td class="td_left">公司社会信用代码</td>
            <td class="td_right">
                {{ formData.companyUnifiedSocialCreditCode }}
            </td>
        </tr>
        <tr v-if="hidesensitiveInfo">
            <td class="td_left">营业执照</td>
            <td class="td_right">
                <imgUpload v-model="formData.businessLicensePic"
                           :disabled="true"
                />
            </td>
        </tr>
        <tr v-if="hidesensitiveInfo">
            <td class="td_left">营业许可证</td>
            <td class="td_right">
                <imgUpload v-model="formData.accountOpenLicensePic"
                           :disabled="true"
                />
            </td>
        </tr>
        <el-divider v-if="isdivider" />      
        <h4 class="tr_h5">收款账户</h4>
        <tr>
            <td class="td_left">银行账户名</td>
            <td class="td_right">{{ formData.receiveMoneyBankAccount }}</td>
        </tr>
        <tr>
            <td class="td_left">收款银行账号</td>
            <td class="td_right">{{ formData.receiveMoneyBankCardNum }}</td>
        </tr>
        <tr>
            <td class="td_left">银行名称</td>
            <td class="td_right">{{ formData.receiveMoneyBankName }}</td>
        </tr>
        <tr>
            <td class="td_left">支行名称</td>
            <td class="td_right">{{ formData.receiveMoneySubBankName }}</td>
        </tr>
    </table>
</template>
<script>
import imgUpload from '../../components/companyQualAduit/imgUpload';

export default {
    name: 'infoForm',
    data(){
        return{

        };
    },
    props: {
        formData: {
            type: Object
        },
        isdivider: {
            type: Boolean
        },
        hidesensitiveInfo: {
            type: Boolean,
            default: true
        }
    },
    components: {
        imgUpload
    }
    
};
</script>
<style lang="scss" scoped>
  .companyTable{
          width: 90%;
           position: relative;
           margin: 0 auto;
            border-collapse:collapse;
            tr{
                min-height: 40px;
                display: flex;
                justify-content: space-between;
                }
    }    
     .td_left{
        text-align: right;
        display: inline-block;
        min-width: 140px;
    }
    .td_right{
        text-align: left;
        display: inline-block;
        min-width: 300px;
    }
    .img-show{
       display: inline-block;
    }
      h4{
        margin: 10px 0;
    }

</style>