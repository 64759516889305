<template>
    <div class="wrap">
        <el-steps :active="activeProgress" align-center>
            <el-step title="企业联系人信息" />
            <el-step title="企业信息" />
            <el-step title="收款帐户" />
            <el-step title="提交审核" />
        </el-steps>
    </div>
</template>

<script>
export default {
    name: 'progressTab',
    props: ['activeProgress']
};
</script>

<style lang='scss' scoped>

</style>