<template>
    <div class="level-rule">
        <div class="title">
            <p class="title-1">等级权益</p>
            <p class="title-2">佣金比例</p>
            <p class="title-3">开黑锁粉天数</p>
        </div>
        <div class="rule" v-for="(item, index) in levelIcon" :key="index"
             :class="{ active: index === zhuankeLevel - 1 }"
        >
            <p class="rule-1">
                <img :src="item.selectedUrl" alt="">
                <span>{{ (index + 1) | formatZhuankeLevel }}</span>
            </p>
            <p class="rule-2">
                {{ (index + 1) | formatCommissionLevel }}
            </p>
            <p class="rule-3">
                {{ vipLockTimeList[index] }}
            </p>
        </div>
    </div>
</template>

<script>
import levelIcon from './level-icon';

export default {
    props: ['vipLockTimeList', 'zhuankeLevel'],

    data() {
        return {
            levelIcon
        };
    },

    filters: {
        formatZhuankeLevel(l) {
            switch (l) {
            case 1:
                return '初级';
            case 2:
                return '中级';
            case 3:
                return '高级';
            default:
                return '';
            }
        },

        formatCommissionLevel(l) {
            switch (l) {
            case 1:
                return '低';
            case 2:
                return '中';
            case 3:
                return '高';
            default:
                return '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.level-rule {
    display: flex;
    .title {
        padding: 20px 0;
        p {
            margin-top: 40px;

            &:first-child {
                margin-top: 0;
            }
        }
        &-1 {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
        }
        &-2 {
            font-size: 15px;
        }
        &-3 {
            font-size: 14px;
        }
    }
    .rule {
        margin-left: 20px;
        padding: 20px 0;
        border-radius: 4px;
        width: 105px;
        font-weight: bold;
        font-size: 16px;
        color: #000;

        &.active {
            background: #FEF8F1;
        }

        > p {
            margin-top: 40px;
            text-align: center;

            &:first-child {
                margin-top: 0;
            }
        }

        &-1 {
            span {
                vertical-align: middle;
            }

            img {
                margin-right: 4px;
                width: 23px;
                height: 18px;
                vertical-align: middle;
            }
        }
    }
}
</style>
